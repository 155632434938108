import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  FormControl,
  Checkbox,
  Slider,
  Input,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import toast from "react-hot-toast";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Layout from "../../components/layout/Layout";
import TableBodies from "../../components/table/TableBodies";
import { useTranslation } from "react-i18next";

import { editFolder, folderDelete, folderFlagOff, getFolders } from "../../redux/actions/FileFolder";
import {
  unPairedScreen,
  pairedScreen,
  getAllScreenById,
  getOneScreen,
  registerScreen,
  registerScreenFlagOff,
  updateScreen,
  screenMoveToFolder,
} from "../../redux/actions/Screen";
import { setFolderId } from "../../redux/actions/FileFolder";
import ConfirmationModel from "../../components/confirmmodel/ConfirmationModel";
import Circularbar from "../../components/circularbar/Circularbar";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import Player from "./components/Player";
import { useSettings } from "src/hooks/use-settings";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";

// icons
import FolderIcon from "@mui/icons-material/Folder";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import { FaTrash } from "react-icons/fa";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ExpandMoreOutlined } from "@mui/icons-material";

function RedesignScreens({ socketRef, user }) {
  const { t } = useTranslation();
  const settings = useSettings();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBTtFIIlgmBrEAdW2-hsMPBQKOYrzLFYcg",
    libraries: ["places"],
  });

  const locationRef = useRef(null);
  const dispatch = useDispatch();
  const { folderId } = useParams();

  useEffect(() => {
    // console.log("[ES]", folderId);
    dispatch(setFolderId(folderId || "root"));
  }, [folderId]);

  const { delMac, isDeleted, isDeleting, screen, isFindScreen, updatedScreen, isPaired, isUnpaired, isScreenRegistered } = useSelector((state) => state.Screen);

  const { currentFolder, currentFolderData, screenFolders, allScreenFolders, screens, allScreens, isFolderCreated, isFolderDeleted, playlists } = useSelector(
    (state) => ({
      currentFolder: state.FileFolder.currentFolder,
      currentFolderData: state.FileFolder.userFolders.find((f) => f._id === state.FileFolder.currentFolder),
      screenFolders: state.FileFolder.userFolders.filter((f) => f.parent === state.FileFolder.currentFolder && f.folderFor === "screen"),
      allScreenFolders: state.FileFolder.userFolders.filter((f) => f.folderFor === "screen"),
      screens: state.Screen.screens.filter((s) => s.folderId == state.FileFolder.currentFolder),
      allScreens: state.Screen.screens,
      isFolderCreated: state.FileFolder.isFolderCreated,
      isFolderDeleted: state.FileFolder.isFolderDeleted,
      playlists: state.Playlist.playlists,
    }),
    shallowEqual
  );

  // console.log(playlists);
  // console.log("[ES]", currentFolder, currentFolderData, allScreens);

  const [oneScreen, setOneScreen] = useState({});
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [rowId, setRowId] = useState("");
  const macRef = useRef();
  const [openCreateScreen, setOpenCreateScreen] = useState(false);
  const [type, setType] = useState("");
  const [playlistId, setPlaylistId] = useState("");
  const [oriantation, setOriantation] = useState("");
  const [syncPlay, setSyncPlay] = useState(false);
  const [code, setCode] = useState("");
  const [showDownloadStatus, setShowDownloadStatus] = useState(false);
  const [preloadAssetPlaylist, setPreloadAssetPlaylist] = useState(false);
  const [showOfflineIndicator, setShowOfflineIndicator] = useState(false);
  const [playBackControl, setPlayBackControl] = useState(false);
  const [brightness, setBrightness] = useState(100);
  const [volume, setVolume] = useState(0);
  const [mute, setMute] = useState(false);
  const [location, setLocation] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [colorCode, setColorCode] = useState("");
  const [backgroundType, setBackgroundType] = useState("");
  const [openPairScreenModel, setOpenPairScreenModel] = useState(false);
  const [openQuickPairScreenModel, setOpenQuickPairScreenModel] = useState(false);
  const [quickAddStep, setQuickAddStep] = useState(0);
  const [openImageModel, setOpenImageModel] = useState(false);
  const [screenId, setScreenId] = useState("");
  const [folderVal, setFolderVal] = useState("");
  const [folderName, setFolderName] = useState("");
  const [ids, setIds] = useState("");
  const [openMoveModel, setOpenMoveModel] = useState(false);
  const [openCreateModel, setOpenCreateModel] = useState(false);
  const [openPairedModel, setOpenPairedModel] = useState(false);
  const [openFolderEditModel, setOpenFolderEditModel] = useState(false);
  const [player, setPlayer] = useState({});
  const [openPlayer, setOpenPlayer] = useState(false);
  const [intervalID, setIntervalID] = useState(null);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  useEffect(() => {
    dispatch(getAllScreenById(user?._id));
    dispatch(getFolders(user?._id));
  }, []);

  useEffect(() => {
    if (isScreenRegistered) {
      // toast.success(t("views.redesignScreens.screenRegisteredSuccess"));
      setOpenPairScreenModel(false);
      emitOnAddScreen();
      dispatch(registerScreenFlagOff());
    }
    if (isPaired) {
      toast.success(t("views.redesignScreens.screenPairedSuccess"));
      dispatch(registerScreenFlagOff());
    }
    if (isUnpaired) {
      toast.success(t("views.redesignScreens.screenUnpairedSuccess"));
      dispatch(registerScreenFlagOff());
    }
    if (isFindScreen) {
      // console.log(screen);
      setCode(screen?.screenCode);
      setDeviceName(screen?.deviceName);
      setOriantation(screen?.oriantation);
      setSyncPlay(screen?.syncPlay);
      setBrightness(screen?.brightness);
      setVolume(screen?.volume);
      setType(screen?.type);
      setMute(screen?.mute);
      setLocation(screen?.location);
      setShowDownloadStatus(screen?.showDownloadStatus);
      setPlayBackControl(screen?.playBackControl);
      setPreloadAssetPlaylist(screen?.preloadAssetPlaylist);
      setShowOfflineIndicator(screen?.showOfflineIndicator);
      setPlaylistId(screen?.playlist?._id);
      setOpenCreateScreen(true);
      dispatch(registerScreenFlagOff());
    }
    if (updatedScreen) {
      toast.success(t("views.redesignScreens.screenUpdatedSuccess"));
      setOpenCreateScreen(false);
      emitOnAddScreen();
      dispatch(registerScreenFlagOff());
    }
    if (isFolderCreated) {
      toast.success(t("views.redesignScreens.folderCreatedSuccess"));
      dispatch(folderFlagOff());
    }
    if (isFolderDeleted) {
      toast.success(t("views.redesignScreens.folderDeletedSuccess"));
      dispatch(folderFlagOff());
    }
  }, [isPaired, isUnpaired, isFindScreen, updatedScreen, isFolderCreated, isFolderDeleted, isScreenRegistered]);

  const resetScreenValues = () => {
    setCode("");
    setPlaylistId("");
    setOriantation("");
    setDeviceName("");
    setType("");
    setSyncPlay(false);
    setShowDownloadStatus(false);
    setShowOfflineIndicator(false);
    setPreloadAssetPlaylist(false);
    setPlayBackControl(false);
    setBrightness(100);
    setVolume(0);
    setMute(false);
    setLocation("");
  };

  const editScreen = async () => {
    if (user?.isMember) {
      await dispatch(
        updateScreen(screen._id, {
          playlistId,
          oriantation,
          deviceName,
          type,
          syncPlay,
          showDownloadStatus,
          showOfflineIndicator,
          preloadAssetPlaylist,
          playBackControl,
          brightness,
          volume,
          mute,
          location,
          backgroundType: {
            type: backgroundType,
            value: colorCode,
          },
          subuser: user?.user?._id,
          platform: "socialMedia",
          role: user?.user?.role,
          folderId: currentFolder,
          lat,
          lng,
        })
      ).then(() => {
        onSyncScreen(screen.mac);
      });
    } else {
      await dispatch(
        updateScreen(screen._id, {
          playlistId,
          oriantation,
          deviceName,
          type,
          syncPlay,
          showDownloadStatus,
          showOfflineIndicator,
          preloadAssetPlaylist,
          playBackControl,
          brightness,
          volume,
          mute,
          location,
          backgroundType: {
            type: backgroundType,
            value: colorCode,
          },
          user: user?._id,
          subuser: user?._id,
          role: user?.role,
          folderId: currentFolder,
          lat,
          lng,
        })
      ).then(() => {
        onSyncScreen(screen.mac);
      });
    }
  };

  const emitOnAddScreen = () => {
    const plylist = playlists.find((p) => p._id === playlistId);
    for (const p in plylist?.screen_mac) {
      socketRef?.current.emit("screen_added", {
        detail: { mac: plylist?.screen_mac[p]?.mac },
      });
    }
  };

  const unPaired = (id, mac) => {
    setRowId(id);
    setOpenDeleteModel(true);
  };

  const confirmUnpaired = () => {
    dispatch(unPairedScreen(rowId));
    emitOnDeleteScreen();
    setOpenDeleteModel(false);
  };

  const paired = (id, mac) => {
    setRowId(id);
    setOpenPairedModel(true);
  };

  const confirmPaired = () => {
    dispatch(pairedScreen(rowId));
    emitOnDeleteScreen();
    setOpenPairedModel(false);
  };

  const emitOnDeleteScreen = () => {
    const id = setTimeout(() => {
      socketRef?.current.emit("screen_deleted", {
        detail: { mac: macRef.current.value },
      });
      clearTimeout(id);
    }, 2000);
  };

  const screenUpdate = (id) => {
    dispatch(getOneScreen(id));
  };

  const pairScreen = async (_playlistId, _deviceName) => {
    const isSameNameScreen = allScreens.filter((s) => s.deviceName === deviceName);

    const currPlaylistId = _playlistId ? _playlistId : playlists?.find((playlist) => playlist?.defaultPlaylist === true)?._id || "";
    const currDeviceName = _deviceName ? _deviceName : `Screen-${Math.random().toString(36).substr(2, 5)}`;

    if (isSameNameScreen.length > 0) {
      toast.error(t("views.redesignScreens.sameNameError"));
    } else {
      const screenData = {
        playlistId: currPlaylistId,
        oriantation: "landscape",
        deviceName: currDeviceName,
        type: "playlist",
        syncPlay: false,
        showDownloadStatus: false,
        showOfflineIndicator: false,
        preloadAssetPlaylist: false,
        playBackControl: false,
        brightness: 100,
        volume: 0,
        mute: false,
        location: "",
        backgroundType: {
          type: "color",
          value: "#0000",
        },
        folderId: "root",
        lat: "",
        lng: "",
        user: user?._id,
        subuser: user?._id,
        role: user?.role,
      };

      if (user?.isMember) {
        screenData.subuser = user?.user?._id;
        screenData.role = user?.user?.role;
      }

      try {
        const newScreen = await dispatch(registerScreen(code, screenData));
        if (newScreen) {
          onPairingModalClose();
          onQuickPairingModalClose();
          onSyncScreen(newScreen.mac);
          if (!_playlistId && !_deviceName) {
            screenUpdate(newScreen._id);
          }
          toast.success(t("views.redesignScreens.screenRegisteredSuccess"));
        } else {
          toast.error(t("views.redesignScreens.screenRegistrationFailed"));
        }
      } catch (error) {
        toast.error(t("views.redesignScreens.pairingError"));
      }
    }
  };

  const onPairingModalClose = () => {
    setOpenPairScreenModel(false);
    setCode("");
  };

  const onQuickPairingModalClose = () => {
    setOpenQuickPairScreenModel(false);
    setCode("");
    setDeviceName("");
    setPlaylistId("");
    setQuickAddStep(0);
  };

  const onScreenMove = (id) => {
    setScreenId(id);
    setOpenMoveModel(true);
  };

  const moveOnFolder = () => {
    dispatch(screenMoveToFolder({ id: screenId, folderId: folderVal }));
    setFolderVal("");
    setOpenMoveModel(false);
  };

  const onScreenFolderDelete = (id) => {
    const childrenscreenfolders = allScreenFolders.filter((f) => f.parent === id) ?? [];
    const childrenscreens = allScreens.filter((s) => s.folderId === id) ?? [];
    if (childrenscreenfolders.length == 0 && childrenscreens.length == 0) {
      setIds(id);
      setOpenCreateModel(true);
    } else {
      toast.error(t("views.redesignScreens.folderContainsItemsError"));
    }
  };

  const onConfirm = () => {
    setOpenCreateModel(false);
    dispatch(folderDelete(ids));
  };

  const onFolderEdit = (id, name) => {
    setIds(id);
    setFolderName(name);
    setOpenFolderEditModel(true);
  };

  const onConfirmFolderEdit = () => {
    if (folderName === "") {
      toast.error(t("views.redesignScreens.fillFolderName"));
    } else {
      dispatch(editFolder(ids, folderName));
      setOpenFolderEditModel(false);
    }
  };

  const onSyncScreen = (_mac) => {
    const relaventScreen = allScreens.find((s) => s.mac === _mac);
    socketRef?.current?.emit("sync", { detail: { mac: _mac } }, function () {
      console.log("succesful sync");
    });
  };

  const onSyncScreenWithToast = (_mac, toast) => {
    const relaventScreen = allScreens.find((s) => s.mac === _mac);
    socketRef?.current?.emit("sync", { detail: { mac: _mac } }, function () {
      toast.success(t("views.redesignScreens.syncSuccess", { deviceName: relaventScreen?.deviceName }));
    });
  };

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  const onGetLatLonByLocatioChange = () => {
    const placeObject = locationRef.current.getPlace();
    const lat = placeObject.geometry.location.lat();
    const lng = placeObject.geometry.location.lng();
    setLocation(placeObject?.name);
    setLat(lat);
    setLng(lng);
  };

  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const PlayerBox = (objectPlay) => {
    setPlayer({ ...objectPlay });
  };

  const onPlaylistPreview = (screenplaylist) => {
    let playerContentList = [];
    if (isObjectEmpty(screenplaylist)) {
      playerContentList = [];
    } else {
      setOpenPlayer(true);

      let counter = 0;
      const _playlistContent = screenplaylist?.playlist?.content?.filter((p) => p?.content !== null);
      if (_playlistContent !== undefined || _playlistContent !== null) {
        _playlistContent?.forEach((player) => {
          playerContentList.push({
            orientation: screenplaylist?.oriantation || "90",
            name: player.content.name,
            url: player.content.url,
            duration: player.duration,
            type: player.content.type,
            widget: player.content.widget,
          });
        });
      }

      const nextPlay = () => {
        if (counter < playerContentList.length && playerContentList.length > 0) {
          const contentPlay = playerContentList[counter];
          PlayerBox(contentPlay);
          counter++;
          setIntervalID(setTimeout(nextPlay, contentPlay.duration));
        } else {
          if (playerContentList.length > 0) {
            counter = 0;
            nextPlay();
          }
        }
      };
      nextPlay();
    }
  };

  const closePlayer = () => {
    clearTimeout(intervalID);
    onPlaylistPreview({});
    setPlayer({});
    setOpenPlayer(false);
  };

  const changeQuickAddStepNext = () => {
    if (quickAddStep === 2 && playlistId) {
      pairScreen(playlistId, deviceName);
    } else if (quickAddStep === 1 && deviceName) {
      setPlaylistId(playlists?.find((playlist) => playlist?.defaultPlaylist === true)?._id || "");
      setQuickAddStep(2);
    } else if (quickAddStep === 0 && code) {
      setDeviceName(`Screen-${Math.random().toString(36).substr(2, 5)}`);
      setQuickAddStep(1);
    }
  };

  const changeQuickAddStepBack = () => {
    if (quickAddStep > 0) {
      setQuickAddStep((prev) => prev - 1);
    }
  };

  //------------------------------------------------------------------

  const quickAddStepRenderContent = () => {
    if (quickAddStep === 0) {
      return (
        <TextField
          variant={"standard"}
          fullWidth
          type={"text"}
          label={t("views.redesignScreens.pairingCodeRequired")}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
        />
      );
    } else if (quickAddStep === 1) {
      return (
        <TextField
          variant={"standard"}
          fullWidth
          type={"text"}
          label={t("views.redesignScreens.deviceNameRequired")}
          value={deviceName}
          onChange={(e) => setDeviceName(e.target.value)}
          required
        />
      );
    } else if (quickAddStep === 2) {
      return (
        <Select
          labelId="playlist_select"
          id="demo-simple-select-standard"
          // label={t("views.redesignScreens.selectPlaylist")}
          fullWidth
          value={playlistId}
          onChange={(e) => setPlaylistId(e.target.value)}
          required={type === "playlist"}>
          {playlists.map((p) => (
            <MenuItem value={p._id}>{p.playlistName}</MenuItem>
          ))}
        </Select>
      );
    }
  };

  //------------------------------------------------------------------

  return (
    <Fragment>
      <input ref={macRef} value={delMac} hidden />
      <Layout title={t("views.redesignScreens.screens")} user={user} playlistAddBtn={false}>
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
          <Container maxWidth={settings.stretch ? false : "xl"}>
            <Grid container spacing={{ xs: 3, lg: 4 }}>
              <Grid xs={12}>
                <Stack direction="row" justifyContent="space-between" spacing={4}>
                  <div>
                    <Typography variant="h4">{t("views.redesignScreens.screens")}</Typography>
                  </div>
                </Stack>
              </Grid>
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}>
                {isDeleting && (
                  <Box
                    sx={{
                      height: "100vh",
                      width: "100vw",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      zIndex: 10000,
                      background: "rgba(0,0,0,0.5)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Circularbar />
                  </Box>
                )}
                <Box
                  sx={{
                    width: "100%",
                    overflowX: "hidden",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}>
                  <TableBodies
                    dataRows={screens}
                    user={user}
                    currentFolderData={currentFolderData}
                    currentFolderPoint={currentFolder}
                    onUnpaired={unPaired}
                    onPaired={paired}
                    onEditScreen={screenUpdate}
                    onPairScreenModel={() => setOpenPairScreenModel(true)}
                    onQuickPairScreenModal={() => setOpenQuickPairScreenModel(true)}
                    onScreenMove={onScreenMove}
                    onScreenFolderDelete={onScreenFolderDelete}
                    onFolderEdit={onFolderEdit}
                    onSyncScreen={onSyncScreenWithToast}
                    onPlaylistPreview={onPlaylistPreview}
                  />
                </Box>
              </Box>
            </Grid>
          </Container>
        </Box>
      </Layout>

      {openPlayer && <Player open={openPlayer} onClose={closePlayer} player={player} />}

      <ConfirmationModel
        openCreateModel={openCreateModel}
        onClose={() => setOpenCreateModel(false)}
        onConfirm={onConfirm}
        Icon={FaTrash}
        title={t("views.redesignScreens.deleteConfirmation")}
      />

      <Dialog open={openMoveModel} onClose={() => setOpenMoveModel(false)} maxWidth={"lg"}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.redesignScreens.selectFolder")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "40vw",
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box
              style={{
                width: "100px",
                height: "100px",
                margin: "0 auto",
              }}>
              <FolderIcon
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <Select value={folderVal} onChange={(e) => setFolderVal(e.target.value)} fullWidth>
                {[{ _id: "root", name: "Home", folderFor: "content", path: [], parent: "" }, ...screenFolders].map((folders) => (
                  <MenuItem value={folders._id}>{folders.name}</MenuItem>
                ))}
              </Select>
            </Box>

            <DialogActions>
              <Button onClick={() => setOpenMoveModel(false)}>{t("views.redesignScreens.close")}</Button>
              <Button onClick={moveOnFolder} variant={"contained"}>
                {t("views.redesignScreens.update")}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openCreateScreen}
        onClose={() => {
          setOpenCreateScreen(false);
          resetScreenValues();
        }}
        maxWidth={"lg"}
        sx={{ zIndex: "10" }}>
        <FormControl component="form" onSubmit={(e) => e.preventDefault()}>
          <DialogTitle>{t("views.redesignScreens.updateScreen")}</DialogTitle>
          <DialogContent sx={{ maxHeight: "500px" }}>
            <Box sx={{ width: { lg: "50vw", xs: "70vw" }, display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Box sx={{ mx: 2 }}>
                <Box style={{ width: "120px", height: "120px", margin: "20px auto" }}>
                  <AddToQueueIcon color="primary" sx={{ fontSize: 120 }} />
                </Box>

                <Box sx={{ margin: "10px 0" }}>
                  <TextField
                    variant={"standard"}
                    fullWidth
                    type={"text"}
                    label={t("views.redesignScreens.deviceNameRequired")}
                    value={deviceName}
                    onChange={(e) => setDeviceName(e.target.value)}
                    required
                  />
                </Box>

                <Box sx={{ margin: "10px 0" }}>
                  <TextField variant={"standard"} fullWidth type={"text"} label={t("views.redesignScreens.pairingCodeRequired")} value={code} disabled />
                </Box>

                <Box sx={{ margin: "10px 0" }}>
                  <TextField label={t("views.redesignScreens.tags")} variant={"standard"} fullWidth />
                </Box>

                <Box sx={{ margin: "10px 0" }}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label">{t("views.redesignScreens.orientationRequired")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label={t("views.redesignScreens.orientation")}
                      value={oriantation}
                      onChange={(e) => setOriantation(e.target.value)}
                      required>
                      <MenuItem value="">
                        <em>{t("views.redesignScreens.none")}</em>
                      </MenuItem>
                      <MenuItem value={"landscape"}>{t("views.redesignScreens.landscape")}</MenuItem>
                      <MenuItem value={"90"}>{t("views.redesignScreens.rotate90")}</MenuItem>
                      <MenuItem value={"180"}>{t("views.redesignScreens.rotate180")}</MenuItem>
                      <MenuItem value={"270"}>{t("views.redesignScreens.rotate270")}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ margin: "10px 0", display: "flex", flexDirection: "column", gap: "10px" }}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label">{t("views.redesignScreens.typeRequired")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label={t("views.redesignScreens.type")}
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      required>
                      <MenuItem value="">
                        <em>{t("views.redesignScreens.none")}</em>
                      </MenuItem>
                      <MenuItem value={"asset"}>{t("views.redesignScreens.task")}</MenuItem>
                      <MenuItem value={"playlist"}>{t("views.redesignScreens.playlist")}</MenuItem>
                      <MenuItem value={"schedule"}>{t("views.redesignScreens.schedule")}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ margin: "10px 0" }}>
                  {type === "playlist" && (
                    <>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="playlist_select">{t("views.redesignScreens.playlist")}</InputLabel>
                        <Select
                          labelId="playlist_select"
                          id="demo-simple-select-standard"
                          label={t("views.redesignScreens.selectPlaylist")}
                          value={playlistId}
                          onChange={(e) => setPlaylistId(e.target.value)}
                          required={type === "playlist"}>
                          {playlists.map((p) => (
                            <MenuItem value={p._id}>{p.playlistName}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Box>

                <Box sx={{ margin: "10px 0" }}>
                  {type === "schedule" && (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">{t("views.redesignScreens.selectScheduleRequired")}</InputLabel>
                      <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" label="Age">
                        <MenuItem value="">
                          <em>{t("views.redesignScreens.none")}</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Box>
              </Box>

              <Accordion sx={{ my: 1 }}>
                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>{t("views.redesignScreens.advanced")}</AccordionSummary>
                <AccordionDetails>
                  <>
                    <Divider />

                    <Box sx={{ margin: "10px 0", zIndex: "1000000000000000" }}>
                      <Autocomplete onLoad={(autocomplete) => (locationRef.current = autocomplete)} onPlaceChanged={onGetLatLonByLocatioChange}>
                        <TextField
                          type={"text"}
                          label={t("views.redesignScreens.location")}
                          variant={"standard"}
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          fullWidth
                        />
                      </Autocomplete>
                    </Box>

                    <Box
                      sx={{
                        margin: "10px 0",
                        "& .slider-style": {
                          height: "2px",
                          width: "98%",
                          "& .MuiSlider-thumb": {
                            height: 20,
                            width: 20,
                            "&:focus, &:hover, &.Mui-active": {
                              boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
                            },
                          },
                          "& .MuiSlider-valueLabel": {
                            fontSize: 12,
                            fontWeight: "normal",
                            top: -6,
                            color: "green",
                          },
                          "& .MuiSlider-mark": {
                            backgroundColor: "#bfbfbf",
                            height: 10,
                            width: 10,
                            borderRadius: 50,
                          },
                        },
                      }}>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">{t("views.redesignScreens.backgroundType")}</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label={t("views.redesignScreens.backgroundType")}
                          value={backgroundType}
                          onChange={(e) => setBackgroundType(e.target.value)}>
                          <MenuItem value="">
                            <em>{t("views.redesignScreens.none")}</em>
                          </MenuItem>
                          <MenuItem value={"default"}>{t("views.redesignScreens.default")}</MenuItem>
                          <MenuItem value={"color"}>{t("views.redesignScreens.color")}</MenuItem>
                          <MenuItem value={"image"}>{t("views.redesignScreens.image")}</MenuItem>
                          <MenuItem value={"transparent"}>{t("views.redesignScreens.transparent")}</MenuItem>
                        </Select>
                      </FormControl>

                      {backgroundType === "color" && (
                        <>
                          <Box sx={{ margin: "1rem 0" }}>
                            <InputLabel id="back-color">{t("views.redesignScreens.color")}</InputLabel>
                            <Input labelId="back-color" type={"color"} value={colorCode} onChange={(e) => setColorCode(e.target.value)} fullWidth />
                          </Box>
                        </>
                      )}
                      {backgroundType === "image" && (
                        <>
                          <Box sx={{ margin: "1rem 0", display: "flex" }}>
                            <TextField value={"abc.png"} aria-readonly={false} disabled fullWidth />
                            <Button variant={"outlined"}>{t("views.redesignScreens.change")}</Button>
                          </Box>
                        </>
                      )}

                      <Box sx={{ margin: "40px 0px 10px 2%" }}>
                        <Slider
                          className="slider-style"
                          value={brightness}
                          onChange={(e) => setBrightness(e.target.value)}
                          marks={[
                            { value: 0, label: "0%" },
                            { value: 50, label: t("views.redesignScreens.brightness") },
                            { value: 100, label: "100%" },
                          ]}
                        />
                      </Box>

                      <Box sx={{ margin: "10px 0px 10px 2%" }}>
                        <Slider
                          className="slider-style"
                          value={volume}
                          onChange={(e) => setVolume(e.target.value)}
                          marks={[
                            { value: 0, label: "0%" },
                            { value: 50, label: t("views.redesignScreens.volume") },
                            { value: 100, label: "100%" },
                          ]}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: "100%",
                        margin: "10px 0",
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        "& .checkbox-label": { fontWeight: "400", fontSize: "15px", lineHeight: "27px", color: "#92959E" },
                        "& .checkbox-wrap": { display: "flex", justifyContent: "flex-start", alignItems: "center" },
                      }}>
                      <Box className="checkbox-wrap">
                        <Checkbox checked={syncPlay} size={"small"} onChange={(e) => setSyncPlay(e.target.checked)} />
                        <Typography variant={"caption"} className="checkbox-label">
                          {t("views.redesignScreens.syncPlay")}
                        </Typography>
                      </Box>

                      <Box className="checkbox-wrap">
                        <Checkbox checked={preloadAssetPlaylist} size={"small"} onChange={(e) => setPreloadAssetPlaylist(e.target.checked)} />
                        <Typography variant={"caption"} className="checkbox-label">
                          {t("views.redesignScreens.preloadAssets")}
                        </Typography>
                      </Box>

                      <Box className="checkbox-wrap">
                        <Checkbox checked={showOfflineIndicator} size={"small"} onChange={(e) => setShowOfflineIndicator(e.target.checked)} />
                        <Typography checked={showOfflineIndicator} variant={"caption"} className="checkbox-label">
                          {t("views.redesignScreens.showOfflineIndicator")}
                        </Typography>
                      </Box>

                      <Box className="checkbox-wrap">
                        <Checkbox checked={showDownloadStatus} size={"small"} onChange={(e) => setShowDownloadStatus(e.target.checked)} />
                        <Typography variant={"caption"} className="checkbox-label">
                          {t("views.redesignScreens.showDownloadControl")}
                        </Typography>
                      </Box>

                      <Box className="checkbox-wrap">
                        <Checkbox checked={playBackControl} size={"small"} onChange={(e) => setPlayBackControl(e.target.checked)} />
                        <Typography variant={"caption"} className="checkbox-label">
                          {t("views.redesignScreens.playbackControl")}
                        </Typography>
                      </Box>

                      <Box className="checkbox-wrap">
                        <Checkbox checked={mute} size={"small"} onChange={(e) => setMute(e.target.checked)} />
                        <Typography variant={"caption"} className="checkbox-label">
                          {t("views.redesignScreens.mute")}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                </AccordionDetails>
              </Accordion>

              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenCreateScreen(false);
                    resetScreenValues();
                  }}>
                  {t("views.redesignScreens.cancel")}
                </Button>
                <Button onClick={editScreen} variant={"contained"} type="submit">
                  {t("views.redesignScreens.update")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </FormControl>
      </Dialog>

      {/* quick pairing screen modal */}
      <Dialog open={openQuickPairScreenModel} onClose={onQuickPairingModalClose} maxWidth={"lg"} sx={{ zIndex: "10" }}>
        <DialogTitle>{t("views.redesignScreens.createScreen")}</DialogTitle>
        <DialogContent sx={{ maxHeight: "400px" }}>
          <Box sx={{ width: { lg: "50vw", xs: "70vw" }, display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <FormControl component="form" onSubmit={(e) => e.preventDefault()} fullWidth>
              <Box style={{ width: "120px", height: "120px", margin: "20px auto" }}>
                <AddToQueueIcon color="primary" sx={{ fontSize: 120 }} />
              </Box>

              <Box sx={{ margin: "10px 0" }}>{quickAddStepRenderContent()}</Box>

              <DialogActions>
                <Button onClick={onQuickPairingModalClose}>{t("views.redesignScreens.cancel")}</Button>
                <Button onClick={changeQuickAddStepBack} variant="contained" disabled={quickAddStep === 0}>
                  {t("views.redesignScreens.back")}
                </Button>
                <Button onClick={changeQuickAddStepNext} variant={"contained"} type="submit">
                  {quickAddStep === 2 ? t("views.redesignScreens.pair") : t("views.redesignScreens.next")}
                </Button>
              </DialogActions>
            </FormControl>
          </Box>
        </DialogContent>
      </Dialog>
      {/* quick pairing screen ending */}

      {/* pairing screen modal */}
      <Dialog open={openPairScreenModel} onClose={onPairingModalClose} maxWidth={"lg"} sx={{ zIndex: "10" }}>
        <DialogTitle>{t("views.redesignScreens.createScreen")}</DialogTitle>
        <DialogContent sx={{ maxHeight: "400px" }}>
          <Box sx={{ width: { lg: "50vw", xs: "70vw" }, display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <FormControl component="form" onSubmit={(e) => e.preventDefault()}>
              <Box style={{ width: "120px", height: "120px", margin: "20px auto" }}>
                <AddToQueueIcon color="primary" sx={{ fontSize: 120 }} />
              </Box>

              <Box sx={{ margin: "10px 0" }}>
                <TextField
                  variant={"standard"}
                  fullWidth
                  type={"text"}
                  label={t("views.redesignScreens.pairingCodeRequired")}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
              </Box>

              <DialogActions>
                <Button onClick={onPairingModalClose}>{t("views.redesignScreens.cancel")}</Button>
                <Button onClick={() => pairScreen()} variant={"contained"} type="submit">
                  {t("views.redesignScreens.pair")}
                </Button>
              </DialogActions>
            </FormControl>
          </Box>
        </DialogContent>
      </Dialog>
      {/* pairing screen ending */}

      {/* image model */}
      <Dialog open={openImageModel} onClose={() => setOpenImageModel(false)} maxWidth={"xl"}>
        <DialogTitle>{t("views.redesignScreens.selectImage")}</DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
      {/* image model ending */}

      <ConfirmationModel
        openCreateModel={openDeleteModel}
        onClose={() => setOpenDeleteModel(false)}
        onConfirm={confirmUnpaired}
        Icon={HelpOutlineIcon}
        title={t("views.redesignScreens.unpairConfirmation")}
      />
      <ConfirmationModel
        openCreateModel={openPairedModel}
        onClose={() => setOpenPairedModel(false)}
        onConfirm={confirmPaired}
        Icon={HelpOutlineIcon}
        title={t("views.redesignScreens.pairConfirmation")}
      />
      <Dialog open={openFolderEditModel} onClose={() => setOpenFolderEditModel(false)}>
        <FormControl component="form" onSubmit={(e) => e.preventDefault()}>
          <DialogTitle style={{ textAlign: "center" }}>{t("views.redesignScreens.editFolder")}</DialogTitle>
          <DialogContent style={{ borderRadius: "50px" }}>
            <Box
              sx={{
                display: "flex",
                gap: "2px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Box style={{ width: "300px", height: "50px", margin: "20px auto" }}>
                <TextField type={"text"} value={folderName} onChange={(e) => setFolderName(e.target.value)} fullWidth />
              </Box>

              <DialogActions>
                <Button onClick={() => setOpenFolderEditModel(false)}>{t("views.redesignScreens.close")}</Button>
                <Button onClick={onConfirmFolderEdit} variant={"contained"} type="submit">
                  {t("views.redesignScreens.save")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </FormControl>
      </Dialog>
    </Fragment>
  );
}

export default RedesignScreens;
